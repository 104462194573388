@use '../helpers.scss' as *;

$spkList: true !default;

@if $spkList == true {
  spk-list {
    --list-s: var(--shape-2);
    --list-active-bg: var(--base-level-20);
    --list-color: var(--base-level-60);
    --list-active-bs: none;

    width: 100%;
    padding: p2r(20 16);
    gap: p2r(8);
    display: flex;
    flex-direction: column;

    &.base-level-20 {
      --list-active-bg: var(--base-level-10);
      --list-active-bs: var(--box-shadow-20);
    }

    [title] {
      color: var(--base-level-60R);
      margin: 0 0.75rem;
      font: var(--paragraph-30);
      line-height: 1.25rem;
    }

    [action],
    [item] {
      border-radius: var(--list-s);
      padding: p2r(8 12);
      font: var(--paragraph-30B);
      color: var(--list-color);
      display: flex;
      align-items: center;
      gap: p2r(8);
      width: 100%;
      user-select: none;
      appearance: none;
      border: 0;
      text-align: left;
      text-wrap: balance;
      background-color: transparent;
      text-decoration: none;

      &:focus {
        outline: none;
      }

      [suffix] {
        margin-left: auto;
        color: var(--base-level-60R);
      }
    }

    :has(input[type='checkbox']),
    :has(input[type='radio']),
    [action] {
      cursor: pointer;
    }

    [item]:has(input[type='checkbox']:checked),
    [action]:has(input[type='checkbox']:checked),
    [item]:has(input[type='radio']:checked),
    [action]:has(input[type='radio']:checked),
    [action].active,
    [action].selected {
      --list-color: var(--base-level-70);

      background-color: var(--list-active-bg);
      box-shadow: var(--list-active-bs);
    }

    &.primary {
      [item]:has(input[type='checkbox']:checked),
      [action]:has(input[type='checkbox']:checked),
      [item]:has(input[type='radio']:checked),
      [action]:has(input[type='radio']:checked),
      [action].active,
      [action].selected {
        spk-icon:first-child {
          color: var(--primary-500);
        }
      }
    }

    &.accent {
      [item]:has(input[type='checkbox']:checked),
      [action]:has(input[type='checkbox']:checked),
      [item]:has(input[type='radio']:checked),
      [action]:has(input[type='radio']:checked),
      [action].active,
      [action].selected {
        spk-icon:first-child {
          color: var(--accent-500);
        }
      }
    }

    &.warn {
      [item]:has(input[type='checkbox']:checked),
      [action]:has(input[type='checkbox']:checked),
      [item]:has(input[type='radio']:checked),
      [action]:has(input[type='radio']:checked),
      [action].active,
      [action].selected {
        spk-icon:first-child {
          color: var(--warn-500);
        }
      }
    }

    &.error {
      [item]:has(input[type='checkbox']:checked),
      [action]:has(input[type='checkbox']:checked),
      [item]:has(input[type='radio']:checked),
      [action]:has(input[type='radio']:checked),
      [action].active,
      [action].selected {
        spk-icon:first-child {
          color: var(--error-500);
        }
      }
    }

    &.success {
      [item]:has(input[type='checkbox']:checked),
      [action]:has(input[type='checkbox']:checked),
      [item]:has(input[type='radio']:checked),
      [action]:has(input[type='radio']:checked),
      [action].active,
      [action].selected {
        spk-icon:first-child {
          color: var(--success-500);
        }
      }
    }
  }
}
