@use '../helpers.scss' as *;

$spkProgressBar: true !default;

@if $spkProgressBar == true {
  spk-progress-bar {
    --pb-h: #{p2r(8)};
    --pb-b: var(--base-level-40);
    --pb-bg: var(--base-level-30);
    --pb-br: var(--shape-2);
    --pbt-bg: var(--base-level-20);
    --pbt-br: inherit;

    display: block;
    width: 100%;
    height: var(--pb-h);
    position: relative;
    overflow: hidden;

    border-radius: var(--pb-br);
    background: var(--pb-bg);

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      transform-origin: top left;

      border-radius: var(--pbt-br);
      background: var(--pbt-bg);
    }

    &.indeterminate {
      .progress-bar {
        right: 0;
        animation: indeterminate-progress-bar-stripes 2s linear infinite;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--base-level-30);
        animation: indeterminate-progress-bar-stripes-background 2s linear infinite;
        transform-origin: top left;
      }
    }

    &.primary {
      --pbt-bg: var(--primary-500);
    }

    &.accent {
      --pbt-bg: var(--accent-500);
    }

    &.warn {
      --pbt-bg: var(--warn-500);
    }

    &.error {
      --pbt-bg: var(--error-500);
    }

    &.success {
      --pbt-bg: var(--success-500);
    }

    &.outlined {
      --pb-b: 1px solid var(--base-level-40);
      --pb-bg: var(--base-level-10);

      &:after {
        background-color: inherit;
      }
    }

    &.flat {
      --pbt-bg: var(--base-level-30);

      &:after {
        background-color: inherit;
      }

      &.primary {
        --pbt-bg: var(--primary-level-30);
      }

      &.accent {
        --pbt-bg: var(--accent-level-30);
      }

      &.warn {
        --pbt-bg: var(--warn-level-30);
      }

      &.error {
        --pbt-bg: var(--error-level-30);
      }

      &.success {
        --pbt-bg: var(--success-level-30);
      }
    }

    &.raised {
      --pbt-bg: var(--base-gradient-30);

      &.primary {
        --pbt-bg: var(--primary-gradient-30);
      }

      &.accent {
        --pbt-bg: var(--accent-gradient-30);
      }

      &.warn {
        --pbt-bg: var(--warn-gradient-30);
      }

      &.error {
        --pbt-bg: var(--error-gradient-30);
      }

      &.success {
        --pbt-bg: var(--success-gradient-30);
      }
    }
  }

  @keyframes indeterminate-progress-bar-stripes-background {
    0% {
      animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
      transform: translateX(0);
    }

    25% {
      animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
      transform: translateX(37.651913%);
    }

    48.35% {
      animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
      transform: translateX(84.386165%);
    }

    100% {
      transform: translateX(160.277782%);
    }
  }

  @keyframes indeterminate-progress-bar-stripes {
    0% {
      transform: translateX(0);
    }
    20% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
      transform: translateX(0);
    }
    59.15% {
      animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
      transform: translateX(83.67142%);
    }
    100% {
      transform: translateX(200.611057%);
    }
  }
}
