@use '../helpers.scss' as *;

$spkTabs: true !default;

@if $spkTabs == true {
  spk-tabs {
    --tabs-bc: var(--base-level-40);
    --tabs-bg: var(--base-level-10);
    --tabs-c: var(--base-level-70);
    --tabs-c-hover: var(--base-level-60);
    --tabs-c-active: var(--base-level-70);
    --tabs-f: var(--paragraph-30);
    --tabs-sel-bg: var(--base-level-70);

    display: flex;
    gap: p2r(16);
    border-bottom: 1px solid var(--base-level-40);

    [tab] {
      display: flex;
      align-items: center;
      gap: p2r(8);
      height: p2r(48);
      font: var(--tabs-f);
      background: var(--tabs-bg);
      border-radius: 0;
      appearance: none;
      border: 0;
      color: var(--tabs-c);
      cursor: pointer;
      position: relative;

      &:focus {
        outline: none;
      }

      &:hover {
        color: var(--tabs-c-hover);
      }

      &.active {
        color: var(--tabs-c-active);

        &::after {
          display: block;
        }
      }

      &:after {
        content: '';
        display: none;
        width: 100%;
        height: 2px;
        background: var(--tabs-sel-bg);
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &.primary {
      --tabs-sel-bg: var(--primary-500);
      --tabs-c-active: var(--primary-500);
    }

    &.accent {
      --tabs-sel-bg: var(--accent-500);
      --tabs-c-active: var(--accent-500);
    }

    &.warn {
      --tabs-sel-bg: var(--warn-500);
      --tabs-c-active: var(--warn-500);
    }

    &.error {
      --tabs-sel-bg: var(--error-500);
      --tabs-c-active: var(--error-500);
    }

    &.success {
      --tabs-sel-bg: var(--success-500);
      --tabs-c-active: var(--success-500);
    }
  }
}
