@use '../../helpers.scss' as *;

* {
  box-sizing: border-box;
}

html,
body,
app-root {
  width: 100vw;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--base-level-20);
}

body {
  margin: 0;
  font: var(--paragraph-30);
  color: var(--base-level-70);

  &.dragging {
    overflow: hidden;
  }
}

.spacer {
  flex: 1 0;
}

.spk-primary {
  color: var(--primary-500);
}

.spk-accent {
  color: var(--accent-500);
}

.spk-warn {
  color: var(--warn-500);
}

.spk-error {
  color: var(--error-500);
}

.spk-success {
  color: var(--success-500);
}
