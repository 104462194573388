@use '../helpers.scss' as *;

$spkColorPicker: true !default;

@if $spkColorPicker == true {
  spk-color-picker {
    position: relative;
    display: block;
    width: p2r(300);
    height: p2r(300);

    canvas {
      background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
        linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%),
        linear-gradient(-45deg, transparent 75%, #808080 75%);
      background-size: 20px 20px;
      background-position:
        0 0,
        0 10px,
        10px -10px,
        -10px 0px;
    }

    &.hsl canvas {
      border-radius: 100%;
      overflow: hidden;
    }

    &.saturation:not(.vertical),
    &.hue:not(.vertical) {
      height: p2r(20);
      border-radius: var(--shape-2);

      canvas {
        border-radius: var(--shape-2);
      }

      .marker {
        top: 50% !important;
      }
    }

    &.saturation.vertical,
    &.hue.vertical {
      width: p2r(20);
      border-radius: var(--shape-2);

      canvas {
        border-radius: var(--shape-2);
      }

      .marker {
        left: 50% !important;
      }
    }

    canvas {
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      transform: translate(-1px, -1px);

      border: 2px solid var(--base-level-10);
      outline: 1px solid var(--base-level-40);
    }

    .marker {
      position: absolute;
      inset: 0;
      width: p2r(20);
      height: p2r(20);
      border-radius: 50%;
      border: 1px solid #fff;
      pointer-events: none;
      transform: translate(-50%, -50%);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    }
  }
}
