@use '../helpers.scss' as *;

$spkStepper: true !default;

@if $spkStepper == true {
  spk-stepper {
    // TODO add variables
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    overflow: hidden;

    [step] {
      flex: 2 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: p2r(8);
      position: relative;
      color: var(--base-level-60R);
      cursor: pointer;
      user-select: none;

      &:first-child {
        align-items: flex-start;
        flex: 1 0;
      }

      &:last-child {
        align-items: flex-end;
        flex: 1 0;
      }

      &:not(.active) spk-radio {
        z-index: 10;
      }

      &:has(~ [step].active) {
        spk-radio {
          --radiod-o: 1;

          .radio {
            border-width: 0;
            background: var(--primary-gradient-20);
          }
        }
      }

      spk-radio {
        .radio {
          --radiod-c: var(--base-level-10);

          width: p2r(16);
          height: p2r(16);
        }
      }

      &.active {
        color: var(--primary-500);
        outline: none;

        spk-radio {
          z-index: 0;
          --radiod-o: 1;

          .radio {
            border-width: 0;
            background: var(--primary-gradient-20);
          }

          &:before {
            content: '';
            position: absolute;
            top: p2r(6);
            left: -9999px;
            z-index: -1;
            right: 0;
            height: p2r(4);
            background-color: var(--primary-500);
          }
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: p2r(6);
      left: 0;
      z-index: 0;
      right: 0;
      height: p2r(4);
      background-color: var(--base-level-30);
    }
  }
}
