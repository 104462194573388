@use '../../helpers.scss' as *;

.loader {
  width: p2r(48);
  height: p2r(48);
  display: grid;
  place-content: center;
  color: var(--base-level-60);
}

.loader,
.loading {
  position: relative;

  &:before {
    content: '';
    width: p2r(16);
    height: p2r(16);
    border: 2px solid var(--loader-c);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: flex;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: auto;
    inset: 0;
    position: absolute;
  }
}

// .loading {
//   &.outlined:before {
//     &.primary {
//       border-color: var(--primary-500);
//     }
//     &.accent {
//       border-color: var(--accent-500);
//     }
//     &.warn {
//       border-color: var(--warn-500);
//     }
//     &.error {
//       border-color: var(--error-500);
//     }
//     &.success {
//       border-color: var(--success-500);
//     }
//   }

//   .raised:before,
//   .flat:before {
//     border-color: #fff;
//   }
// }

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
