@use '../helpers.scss' as *;

$spkDatepicker: true !default;

@if $spkDatepicker == true {
  spk-daterange-input,
  spk-datepicker-input {
    spk-form-field-popover {
      .input-wrap .input {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
      }

      .masked-value {
        font: var(--paragraph-30B);
        color: var(--base-level-60);

        + input + input,
        + input {
          opacity: 0;
          height: 0;
          width: 0;
        }
      }

      [suffix] + [suffix].default-indicator {
        display: none;
      }
    }

    spk-datepicker {
      z-index: 10000;
      position: relative;
      --dp-width: #{p2r(240)};
    }
  }

  spk-daterange-input {
    spk-popover .popover {
      --dp-width: #{p2r(300)};
      min-width: var(--dp-width);
    }

    @for $i from 1 through 3 {
      spk-form-field-popover.columns-#{$i} {
        $gap: 1rem;

        spk-popover .popover {
          --dp-width: calc(#{p2r($i * 300)} + #{(($i - 1) * $gap)});
        }
      }
    }
  }

  spk-datepicker {
    --dp-sel-bg: var(--base-level-60);
    --dp-sel-bw: 0;
    --dp-sel-bc: transparent;
    --dp-sel-c: #fff;
    --dp-day-c: var(--base-level-60);
    --dp-day-g: #{p2r(4 0)};
    --dp-day-f: var(--paragraph-20);
    --dp-width: #{p2r(300)};

    width: 100%;
    max-width: var(--dp-width);
    display: flex;
    flex-direction: column;

    @for $i from 1 through 3 {
      &.columns-#{$i} {
        $gap: 1rem;

        --dp-width: calc(#{p2r($i * 300)} + #{(($i - 1) * $gap)});
      }
    }

    &.as-range {
      section {
        .days > div {
          &.sel {
            background: var(--dp-sel-bg);
            opacity: 0.5;
          }

          &.month-start,
          &.week-start,
          &.first {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
          }
          &.month-end,
          &.week-end,
          &.last {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
          }

          &.first,
          &.last {
            opacity: 1;
          }
        }
      }

      .month .days > div.out-of-scope {
        opacity: 0;
        pointer-events: none;
      }
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: p2r(4 0);
      gap: p2r(0);

      .title {
        font: var(--paragraph-20);
      }

      button {
        appearance: none;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        height: p2r(32);
        color: var(--base-level-60);

        &:focus {
          outline: none;
        }
      }
    }

    .months-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1rem;
    }

    .month {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr 7fr;
      gap: var(--dp-day-g);
      align-items: flex-start;

      .days,
      .weekdays {
        display: grid;
        grid-column: 1 / -1;
        gap: var(--dp-day-g);
        grid-template-columns: subgrid;
        position: relative;

        > .days {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-template-rows: repeat(7, 1fr);
          gap: var(--dp-day-g);
          z-index: 0;
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1/1;
          cursor: pointer;
          position: relative;
          z-index: 1;
          color: var(--dp-day-c);
          font: var(--dp-day-f);
          user-select: none;

          &.sel {
            color: var(--dp-sel-c);
            transition: color 75ms linear;
          }

          &.out-of-scope {
            opacity: 0.4;
          }

          &.sel-el {
            position: relative;
            top: 0;
            left: 0;
            transition:
              transform 75ms linear,
              opacity 75ms linear;
            background: var(--dp-sel-bg);
            border-radius: 50%;
            border: var(--dp-sel-bw) solid var(--dp-sel-bc);
            opacity: 0;
            box-sizing: border-box;
          }
        }
      }

      .weekdays {
        > div {
          color: var(--base-level-70);
        }
      }
    }

    &.primary {
      --dp-sel-bg: var(--primary-500);
    }

    &.accent {
      --dp-sel-bg: var(--accent-500);
    }

    &.warn {
      --dp-sel-bg: var(--warn-500);
    }

    &.error {
      --dp-sel-bg: var(--error-500);
    }

    &.success {
      --dp-sel-bg: var(--success-500);
    }

    &.outlined {
      --dp-sel-bg: var(--base-level-10);
      --dp-sel-c: var(--base-level-70);
      --dp-sel-bw: 2px;
      --dp-sel-bc: var(--base-level-40);

      &.primary {
        --dp-sel-bc: var(--primary-500);
      }

      &.accent {
        --dp-sel-bc: var(--accent-500);
      }

      &.warn {
        --dp-sel-bc: var(--warn-500);
      }

      &.error {
        --dp-sel-bc: var(--error-500);
      }

      &.success {
        --dp-sel-bc: var(--success-500);
      }
    }

    &.raised {
      --dp-sel-bg: var(--base-gradient-20);

      &.primary {
        --dp-sel-bg: var(--primary-gradient-20);
      }

      &.accent {
        --dp-sel-bg: var(--accent-gradient-20);
      }

      &.warn {
        --dp-sel-bg: var(--warn-gradient-20);
      }

      &.error {
        --dp-sel-bg: var(--error-gradient-20);
      }

      &.success {
        --dp-sel-bg: var(--success-gradient-20);
      }
    }
  }
}
