@use '../helpers.scss' as *;

$spkMenu: true !default;

@if $spkMenu == true {
  spk-menu {
    --menu-mh: #{p2r(320)};

    display: inline-flex;
    position: relative;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.stretch {
      width: 100%;

      [trigger] > * {
        width: 100%;
      }
    }

    [trigger]:has(spk-icon.open-indicator) {
      position: relative;

      > :first-child {
        padding-right: p2r(32);
      }

      .open-indicator {
        position: absolute;
        right: p2r(8);
        top: 50%;
        transform: translateY(-50%);
        color: var(--base-level-60);
        font-size: p2r(16);
        font-weight: inherit;
        pointer-events: none;
        transition: transform 75ms ease-in-out;
      }

      &.is-open .open-indicator {
        transform: translateY(-50%) rotate(-180deg);
      }
    }

    spk-popover {
      flex: 1 0;

      .popover {
        padding: 0;
      }

      .overlay ~ .form-field-wrap {
        margin: p2r(8 8 0);

        spk-form-field {
          z-index: 10;
          background-color: var(--base-level-10);

          &.hidden {
            opacity: 0;
            height: 0;
            width: 0;
            min-width: 0;
            min-height: 0;
            margin: 0;
          }
        }
      }
    }

    spk-popover:has(:not(.options.searching)) button {
      order: initial !important;
    }

    spk-popover .options {
      z-index: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: p2r(4);
      padding: 0;
      margin: p2r(4 0 0);
      max-height: var(--menu-mh);
      overflow: auto;

      &.searching {
        button[disabled] {
          display: none;
        }

        button[value='0'] {
          display: none;
        }

        *:not(button) {
          display: none;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        min-height: p2r(4);
      }

      [title] {
        font: var(--paragraph-30);
        color: var(--base-level-60);
        padding: p2r(8 12);
        margin: 0;
        user-select: none;
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: var(--base-level-10);
        border-bottom: 1px solid var(--base-level-40);
      }
      button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        min-height: p2r(32);
        margin: p2r(0 8);
        padding: p2r(0 8);
        gap: p2r(8);
        width: calc(100% - #{p2r(16)});
        border-radius: var(--shape-2);
        appearance: none;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        color: var(--base-level-70);

        &[disabled] {
          opacity: 0.3;
          cursor: initial;

          &:hover {
            background-color: transparent;
          }
        }

        [suffix] {
          order: 999;
          color: var(--base-level-60);
          font: var(--paragraph-30);
          justify-self: flex-end;
          display: inline-flex;
          flex: 1 0;
          justify-content: flex-end;
        }

        spk-icon {
          font-size: p2r(16);
        }

        &.active,
        &:hover {
          background-color: var(--base-level-30);
          color: var(--base-level-70);
        }

        &.focused {
          background-color: var(--primary-level-20);
          color: var(--base-level-70);

          &:hover {
            background-color: var(--primary-level-30);
          }
        }
      }
    }
  }
}
