@use '../helpers.scss' as *;

$spkDialog: true !default;

@if $spkDialog == true {
  dialog[spkDialog] {
    --dialog-p: #{p2r(16)};
    --dialog-g: #{p2r(8)};
    --dialog-bg: var(--base-level-10);

    border: 1px solid var(--base-level-40);
    border-radius: var(--shape-2);
    background-color: var(--dialog-bg);
    box-shadow: var(--box-shadow-40);
    color: var(--base-level-60);
    font: var(--paragraph-30);
    padding: 0;
    margin: auto;
    max-width: initial;
    max-height: 96vh;
    position: relative;
    z-index: 0;

    .closeable-overlay {
      position: fixed;
      inset: 0;
      opacity: 0;
      z-index: 1;
    }

    .content {
      position: relative;
      z-index: 2;
    }

    &:focus {
      outline: none;
    }

    .dialog-content {
      position: relative;
      z-index: 2;
    }

    .dialog-backdrop {
      position: fixed;
      inset: 0;
      opacity: 0;
      z-index: 1;
    }

    [header],
    [content],
    [footer] {
      width: 100%;
    }

    [header] {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--dialog-p);
      gap: var(--dialog-g);

      &.sticky {
        background-color: var(--dialog-bg);
        border-bottom: 1px solid var(--base-level-40);
        box-shadow: var(--box-shadow-35);
        position: sticky;
        top: 0;
      }
    }

    [content] {
      padding: 0 var(--dialog-p);

      &:not(:has(~ [footer])) {
        padding-bottom: var(--dialog-p);
      }
    }

    [footer] {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: var(--dialog-p);
      gap: var(--dialog-g);

      &.sticky {
        background-color: var(--dialog-bg);
        border-top: 1px solid var(--base-level-40);
        box-shadow: var(--box-shadow-35);
        position: sticky;
        bottom: 0;
      }
    }
  }
}
