@use '../helpers.scss' as *;

$spkRadio: true !default;

@if $spkRadio == true {
  spk-radio {
    --radio-bw: #{p2r(1)};
    --radio-bg: var(--base-level-30);
    --radio-bc: var(--base-level-40);
    --radio-c: var(--base-level-60);
    --radiod-o: 0;
    --radiod-c: var(--base-level-60);

    display: flex;
    align-items: center;
    gap: p2r(8);
    cursor: pointer;
    user-select: none;
    position: relative;

    > input[type='radio'] {
      appearance: none;
      position: absolute;
      inset: 0;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    &:has(input[type='radio']:checked),
    &.active {
      --radiod-o: 1;
    }

    .radio {
      display: flex;
      align-items: center;
      justify-content: center;
      width: p2r(20);
      height: p2r(20);
      cursor: pointer;
      position: relative;
      border-radius: 50%;
      border: var(--radio-bw) solid var(--radio-bc);
      background: var(--radio-bg);
      font-size: p2r(12);
      color: var(--radio-c);

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: p2r(6);
        height: p2r(6);
        background-color: var(--radiod-c);
        opacity: var(--radiod-o);
        transition: opacity 125ms linear;
      }
    }

    &.primary {
      --radio-c: var(--primary-500);
      --radiod-c: var(--primary-500);
      --radio-bg: var(--primary-level-30);
      --radio-bc: var(--primary-level-40);
    }

    &.accent {
      --radio-c: var(--accent-500);
      --radiod-c: var(--accent-500);
      --radio-bg: var(--accent-level-30);
      --radio-bc: var(--accent-level-40);
    }

    &.warn {
      --radio-c: var(--warn-500);
      --radiod-c: var(--warn-500);
      --radio-bg: var(--warn-level-30);
      --radio-bc: var(--warn-level-40);
    }

    &.error {
      --radio-c: var(--error-500);
      --radiod-c: var(--error-500);
      --radio-bg: var(--error-level-30);
      --radio-bc: var(--error-level-40);
    }

    &.success {
      --radio-c: var(--success-500);
      --radiod-c: var(--success-500);
      --radio-bg: var(--success-level-30);
      --radio-bc: var(--success-level-40);
    }

    &.outlined {
      --radio-bg: var(--base-level-10);

      &.primary,
      &.accent,
      &.warn,
      &.error,
      &.success {
        --radio-bc: var(--base-level-40);
      }
    }

    &.flat,
    &.raised {
      --radio-c: #fff;
      --radio-bc: var(--base-level-40);
      --radio-bg: var(--base-level-30);
      --radiod-c: #fff;
    }

    &.flat:has(input[type='radio']:checked),
    &.flat.active {
      --radio-bw: 0;
      --radio-bg: var(--grey-500);
      --radiod-c: #fff;

      &.primary {
        --radio-bg: var(--primary-500);
      }

      &.accent {
        --radio-bg: var(--accent-500);
      }

      &.warn {
        --radio-bg: var(--warn-500);
      }

      &.error {
        --radio-bg: var(--error-500);
      }

      &.success {
        --radio-bg: var(--success-500);
      }
    }

    &.raised:has(input[type='radio']:checked),
    &.raised.active {
      --radio-bw: 0;
      --radio-bg: var(--base-gradient-20);

      &.primary {
        --radio-bg: var(--primary-gradient-20);
      }

      &.accent {
        --radio-bg: var(--accent-gradient-20);
      }

      &.warn {
        --radio-bg: var(--warn-gradient-20);
      }

      &.error {
        --radio-bg: var(--error-gradient-20);
      }

      &.success {
        --radio-bg: var(--success-gradient-20);
      }
    }
  }
}
