@use '../helpers.scss' as *;

$spkChip: true !default;
$spkChipShadow: false !default;

@if $spkChip == true {
  spk-chip {
    --chip-h: #{p2r(32)};
    --chip-s: #{p2r(16)};
    --chip-c: var(--base-level-70);
    --chip-ic: var(--base-level-70);
    --chip-bg: var(--base-level-10);
    --chip-bc: var(--base-level-40);

    @if $spkChipShadow != false {
      --chip-bs: var(--box-shadow-10);
    } @else {
      --chip-bs: none;
    }

    display: inline-flex;
    align-items: center;
    gap: p2r(8);
    padding: p2r(0 12);

    font: var(--paragraph-30B);
    color: var(--chip-c);
    height: var(--chip-h);
    border-radius: var(--chip-s);
    border: 1px solid var(--chip-bc);
    box-shadow: var(--chip-bs);
    background: var(--chip-bg);
    white-space: nowrap;

    spk-icon {
      color: var(--chip-ic);
      font-size: p2r(16);
      font-weight: inherit;
    }

    [avatar] {
      height: calc(var(--chip-h) - #{p2r(12)});
      width: calc(var(--chip-h) - #{p2r(12)});
      border-radius: 50%;
    }

    &.small {
      --chip-h: #{p2r(24)};
      --chip-s: #{p2r(12)};

      font: var(--paragraph-40B);
      padding: p2r(0 8);
      gap: p2r(4);

      spk-icon {
        font-size: p2r(12);
      }
    }

    &[disabled] {
      opacity: 0.5;
    }

    &[read-only],
    &[read-only='true'],
    &[readonly='true'],
    &[readonly],
    &.readonly {
      pointer-events: none;
      user-select: none;
    }

    &.primary {
      --chip-ic: var(--primary-500);
    }

    &.accent {
      --chip-ic: var(--accent-500);
    }

    &.warn {
      --chip-ic: var(--warn-500);
    }

    &.error {
      --chip-ic: var(--error-500);
    }

    &.success {
      --chip-ic: var(--success-500);
    }

    &.simple {
      border: 0;

      --chip-c: var(--base-level-70);

      &.primary {
        --chip-c: var(--primary-500);
      }

      &.accent {
        --chip-c: var(--accent-500);
      }

      &.warn {
        --chip-c: var(--warn-500);
      }

      &.error {
        --chip-c: var(--error-500);
      }

      &.success {
        --chip-c: var(--success-500);
      }
    }

    &.simple,
    &.outlined {
      --chip-c: var(--base-level-70);
      --chip-bg: var(--base-level-20);

      transition: color 80ms linear;

      &.primary {
        --loader-c: var(--primary-500);

        --chip-bg: var(--primary-level-20);
        --chip-bc: var(--primary-level-40);
      }

      &.accent {
        --loader-c: var(--accent-500);

        --chip-bg: var(--accent-level-20);
        --chip-bc: var(--accent-level-40);
      }

      &.warn {
        --loader-c: var(--warn-500);

        --chip-bg: var(--warn-level-20);
        --chip-bc: var(--warn-level-40);
      }

      &.error {
        --loader-c: var(--error-500);

        --chip-bg: var(--error-level-20);
        --chip-bc: var(--error-level-40);
      }

      &.success {
        --loader-c: var(--success-500);

        --chip-bg: var(--success-level-20);
        --chip-bc: var(--success-level-40);
      }
    }

    &.flat {
      --loader-c: var(--base-level-10);
      --chip-c: #fff;
      --chip-ic: #fff;
      --chip-bg: #{light-dark(var(--grey-600), var(--grey-600))};

      border: 0;

      &.primary {
        --chip-c: var(--primary-contrast-500);
        --chip-bg: var(--primary-500);
      }

      &.accent {
        --chip-c: var(--accent-contrast-500);
        --chip-bg: var(--accent-500);
      }

      &.warn {
        --chip-c: var(--warn-contrast-500);
        --chip-bg: var(--warn-500);
      }

      &.error {
        --chip-c: var(--error-contrast-500);
        --chip-bg: var(--error-500);
      }

      &.success {
        --chip-c: var(--success-contrast-500);
        --chip-bg: var(--success-500);
      }
    }

    &.raised {
      --loader-c: var(--base-level-10);
      --chip-c: #fff;
      --chip-ic: #fff;
      --chip-bg: var(--base-gradient-60);

      border: 0;
      box-shadow: none;

      &.primary {
        --chip-c: var(--primary-contrast-500);
        --chip-bg: var(--primary-gradient-20);
      }

      &.accent {
        --chip-c: var(--accent-contrast-500);
        --chip-bg: var(--accent-gradient-20);
      }

      &.warn {
        --chip-c: var(--warn-contrast-500);
        --chip-bg: var(--warn-gradient-20);
      }

      &.error {
        --chip-c: var(--error-contrast-500);
        --chip-bg: var(--error-gradient-20);
      }

      &.success {
        --chip-c: var(--success-contrast-500);
        --chip-bg: var(--success-gradient-20);
      }
    }
  }
}
