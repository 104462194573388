@use '../helpers.scss' as *;

$spkCheckbox: true !default;

@if $spkCheckbox == true {
  spk-checkbox {
    --box-bw: #{p2r(1)};
    --box-bc: var(--base-level-40);
    --box-c: var(--base-level-60);
    --box-bg: var(--base-level-30);

    display: flex;
    align-items: center;
    gap: p2r(8);
    cursor: pointer;
    user-select: none;
    position: relative;

    > input[type='checkbox'] {
      appearance: none;
      position: absolute;
      inset: 0;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: p2r(20);
      height: p2r(20);
      cursor: pointer;
      position: relative;
      border-radius: var(--shape-1);
      border: var(--box-bw) solid var(--box-bc);
      background: var(--box-bg);
      font-size: p2r(12);
      color: var(--box-c);
    }

    spk-icon {
      opacity: 0;
      font-weight: bold;

      &.indeterminate-indicator {
        display: none;
      }
    }

    &.indeterminate {
      .box spk-icon {
        &.indeterminate-indicator {
          display: block;
        }

        &.default-indicator {
          display: none;
        }
      }
    }

    &:has(input[type='checkbox']:checked),
    &.active {
      spk-icon {
        opacity: 1;
      }
    }

    &.primary {
      --box-bg: var(--primary-level-30);
      --box-bc: var(--primary-level-40);
      --box-c: var(--primary-500);
    }

    &.accent {
      --box-bg: var(--accent-level-30);
      --box-bc: var(--accent-level-40);
      --box-c: var(--accent-500);
    }

    &.warn {
      --box-bg: var(--warn-level-30);
      --box-bc: var(--warn-level-40);
      --box-c: var(--warn-500);
    }

    &.error {
      --box-bg: var(--error-level-30);
      --box-bc: var(--error-level-40);
      --box-c: var(--error-500);
    }

    &.success {
      --box-bg: var(--success-level-30);
      --box-bc: var(--success-level-40);
      --box-c: var(--success-500);
    }

    &.outlined {
      --box-bg: var(--base-level-10);

      &.primary,
      &.accent,
      &.warn,
      &.error,
      &.success {
        --box-bg: var(--base-level-10);
        --box-bc: var(--base-level-40);
      }
    }

    &.flat,
    &.raised {
      &.primary,
      &.accent,
      &.warn,
      &.error,
      &.success {
        --box-bc: var(--base-level-40);
        --box-c: var(--base-level-60);
        --box-bg: var(--base-level-30);
      }
    }

    &.flat:has(input[type='checkbox']:checked),
    &.flat.active {
      @extend %flat;
    }

    &.raised:has(input[type='checkbox']:checked),
    &.raised.active {
      @extend %raised;
    }
  }

  .sparkle-popup-menu spk-option[selected] {
    spk-checkbox {
      spk-icon {
        opacity: 1;
      }

      &.flat {
        @extend %flat;
      }

      &.raised {
        @extend %raised;
      }
    }
  }

  %flat {
    --box-bw: 0;
    --box-bg: var(--grey-500);
    --box-c: #fff;

    &.primary {
      --box-bg: var(--primary-500);
    }

    &.accent {
      --box-bg: var(--accent-500);
    }

    &.warn {
      --box-bg: var(--warn-500);
    }

    &.error {
      --box-bg: var(--error-500);
    }

    &.success {
      --box-bg: var(--success-500);
    }
  }

  %raised {
    --box-bw: 0;
    --box-bg: var(--base-gradient-20);
    --box-c: #fff;

    &.primary {
      --box-bg: var(--primary-gradient-20);
    }

    &.accent {
      --box-bg: var(--accent-gradient-20);
    }

    &.warn {
      --box-bg: var(--warn-gradient-20);
    }

    &.error {
      --box-bg: var(--error-gradient-20);
    }

    &.success {
      --box-bg: var(--success-gradient-20);
    }
  }
}
