@use '../helpers.scss' as *;

$spkRangeSlider: true !default;

@if $spkRangeSlider == true {
  spk-range-slider {
    --rs-h: #{p2r(40)};
    --rs-f: var(--paragraph-30);
    --rs-unit-g: #{p2r(12)};
    --rs-unit-c: var(--base-level-60);
    --rst: var(--base-level-30);
    --rst-bc: var(--base-level-10);
    --rst-bg: var(--base-level-60R);
    --rst-h: #{p2r(8)};
    --rst-s: var(--shape-1);
    --rs-thumb-c: var(--base-level-40);
    --rs-thumb-bc: var(--base-level-10);
    --rs-thumb-value-bg: var(--base-level-30);
    --rs-thumb-value-c: var(--base-level-30R);
    --rs-thumb-si: #{p2r(16)};

    display: flex;
    flex-direction: column;
    width: 100%;
    font: var(--rs-f);

    .label {
      display: flex;
      align-items: center;
      gap: p2r(8);
      padding-bottom: p2r(4);

      > label {
        display: flex;
        align-items: center;
        gap: p2r(4);
      }

      spk-icon {
        color: var(--grey-500);
        font-size: p2r(16);
      }

      &:empty {
        display: none;
      }
    }

    input[type='range'] {
      appearance: none;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0;
      margin: 0;
      z-index: 1000;
    }

    .input-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
      gap: var(--rs-unit-g);
    }

    .thumb-wrap {
      position: absolute;
      top: 0;
    }

    .thumb {
      position: relative;
      margin-top: calc(-1 * var(--rs-thumb-si) / 4);
      width: var(--rs-thumb-si);
      height: var(--rs-thumb-si);
      border-radius: 50%;
      background-color: var(--rs-thumb-c);
      border: 1px solid var(--rs-thumb-bc);
      box-shadow: var(--box-shadow-10);
      transform: translateX(-50%);

      &.always-show .value-indicator {
        opacity: 1;
      }
    }

    .track-wrap {
      position: relative;
      width: 100%;
      height: var(--rst-h);
    }

    .track {
      position: relative;
      width: 100%;
      height: var(--rst-h);
      background-color: var(--rst);
      border-radius: var(--rst-s);
      background-color: var(--rst);
      border: 1px solid var(--rst-bc);
      overflow: hidden;
    }

    .track-filled {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: var(--rst-bg);
      width: 0;
    }

    .min-indicator,
    .max-indicator {
      white-space: nowrap;
      color: var(--rs-unit-c);
    }

    input[type='range']:hover ~ .thumb-wrap .value-indicator,
    input[type='range']:focus ~ .thumb-wrap .value-indicator {
      opacity: 1;
    }

    .value-indicator {
      position: absolute;
      top: p2r(-30);
      height: p2r(24);
      border-radius: var(--shape-2);
      left: 50%;
      margin: auto;
      transform: translateX(-50%);
      padding: p2r(4 8);
      background-color: var(--rs-thumb-value-bg);
      color: var(--rs-thumb-value-c);
      white-space: nowrap;
      opacity: 0;
      transition: opacity 125ms linear;
      // transition-delay: 1s;
      user-select: none;
      pointer-events: none;

      &:after {
        content: '';
        border-top: p2r(6) solid var(--rs-thumb-value-bg);
        border-left: p2r(6) solid transparent;
        border-right: p2r(6) solid transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: p2r(-6);
      }
    }

    input[type='range'][readonly] {
      user-select: none;
      pointer-events: none;

      ~ .track .track-filled {
        transition: width 125ms linear;
        border-radius: var(--rst-s);
      }

      ~ .thumb-wrap {
        display: none;
      }
    }

    &.primary {
      --rst-bg: var(--primary-500);
      --rst: var(--primary-level-40);
      --rs-thumb-c: var(--primary-level-50);
      --rs-thumb-bc: var(--base-level-10);
      --rs-thumb-value-bg: var(--primary-level-50);
      --rs-thumb-value-c: var(--primary-level-50R);
    }

    &.accent {
      --rst-bg: var(--accent-500);
      --rst: var(--accent-level-40);
      --rs-thumb-c: var(--accent-level-50);
      --rs-thumb-bc: var(--base-level-10);
      --rs-thumb-value-bg: var(--accent-level-50);
      --rs-thumb-value-c: var(--accent-level-50R);
    }

    &.warn {
      --rst-bg: var(--warn-500);
      --rst: var(--warn-level-40);
      --rs-thumb-c: var(--warn-level-50);
      --rs-thumb-bc: var(--base-level-10);
      --rs-thumb-value-bg: var(--warn-level-50);
      --rs-thumb-value-c: var(--warn-level-50R);
    }

    &.error {
      --rst-bg: var(--error-500);
      --rst: var(--error-level-40);
      --rs-thumb-c: var(--error-level-50);
      --rs-thumb-bc: var(--base-level-10);
      --rs-thumb-value-bg: var(--error-level-50);
      --rs-thumb-value-c: var(--error-level-50R);
    }

    &.success {
      --rst-bg: var(--success-500);
      --rst: var(--success-level-40);
      --rs-thumb-c: var(--success-level-50);
      --rs-thumb-bc: var(--base-level-10);
      --rs-thumb-value-bg: var(--success-level-50);
      --rs-thumb-value-c: var(--success-level-50R);
    }

    &.outlined {
      --rst: var(--base-level-10);
      --rst-bc: var(--base-level-40);
      --rs-thumb-c: var(--base-level-50);
      --rs-thumb-bc: var(--base-level-10);

      &.primary,
      &.accent,
      &.warn,
      &.error,
      &.success {
        --rst: var(--base-level-10);
      }

      &.primary {
        --rs-thumb-c: var(--primary-level-40);
        --rs-thumb-bc: var(--base-level-10);
      }

      &.accent {
        --rs-thumb-c: var(--accent-level-40);
        --rs-thumb-bc: var(--base-level-10);
      }

      &.warn {
        --rs-thumb-c: var(--warn-level-40);
        --rs-thumb-bc: var(--base-level-10);
      }

      &.error {
        --rs-thumb-c: var(--error-level-40);
        --rs-thumb-bc: var(--base-level-10);
      }

      &.success {
        --rs-thumb-c: var(--success-level-40);
        --rs-thumb-bc: var(--base-level-10);
      }
    }

    &.flat {
      --rs-thumb-c: #fff;
      --rs-thumb-bc: var(--base-level-40);

      &.primary,
      &.accent,
      &.warn,
      &.error,
      &.success {
        --rst: var(--base-level-30);
        --rs-thumb-c: #fff;
        --rs-thumb-bc: var(--base-level-40);
      }
    }

    &.raised {
      --rst-bg: var(--base-gradient-20);
      --rs-thumb-c: #fff;
      --rs-thumb-bc: var(--base-level-40);

      &.primary,
      &.accent,
      &.warn,
      &.error,
      &.success {
        --rst: var(--base-level-30);
        --rs-thumb-c: #fff;
        --rs-thumb-bc: var(--base-level-40);
      }

      &.primary {
        --rst-bg: var(--primary-gradient-20);
      }

      &.accent {
        --rst-bg: var(--accent-gradient-20);
      }

      &.warn {
        --rst-bg: var(--warn-gradient-20);
      }

      &.error {
        --rst-bg: var(--error-gradient-20);
      }

      &.success {
        --rst-bg: var(--success-gradient-20);
      }
    }
  }
}
