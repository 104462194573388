@use 'sass:list';
@use '../../helpers.scss' as *;
@use './palette-variables.scss' as palettes;

@mixin palette($palette, $name) {
  @each $key, $value in $palette {
    --#{"" + $name}-#{$key}: #{list.nth($value, 1)};
    // --#{"" + $name}-rgb-#{$key}: #{list.nth($value, 2)};
    --#{"" + $name}-contrast-#{$key}: var(--#{list.nth($value, 3)}-primary-text);
  }
}

:root {
  --font-size: 16px;
  font-size: var(--font-size);
}

html body {
  --sparkle-light: initial;
  --sparkle-dark: ;
  color-scheme: light dark;

  @include palette(palettes.$topaz-palette, primary);
  @include palette(palettes.$violet-palette, accent);
  @include palette(palettes.$amber-palette, warn);
  @include palette(palettes.$ruby-palette, error);
  @include palette(palettes.$emerald-palette, success);
  @include palette(palettes.$mono-palette, grey);

  --display-10: 600 #{p2r(80)} / normal Inter Tight, sans-serif;
  --display-20: 600 #{p2r(72)} / normal Inter Tight, sans-serif;
  --display-30: 600 #{p2r(64)} / normal Inter Tight, sans-serif;
  --display-40: 600 #{p2r(56)} / normal Inter Tight, sans-serif;
  --display-50: 600 #{p2r(48)} / normal Inter Tight, sans-serif;

  --title-10: 500 #{p2r(40)} / normal Inter Tight, sans-serif;
  --title-20: 500 #{p2r(32)} / normal Inter Tight, sans-serif;
  --title-30: 500 #{p2r(24)} / normal Inter Tight, sans-serif;

  --title-10B: 600 #{p2r(40)} / normal Inter Tight, sans-serif;
  --title-20B: 600 #{p2r(32)} / normal Inter Tight, sans-serif;
  --title-30B: 600 #{p2r(24)} / normal Inter Tight, sans-serif;

  --paragraph-10: 500 #{p2r(18)} / normal Inter Tight, sans-serif;
  --paragraph-20: 500 #{p2r(16)} / normal Inter Tight, sans-serif;
  --paragraph-30: 500 #{p2r(14)} / normal Inter Tight, sans-serif;
  --paragraph-40: 500 #{p2r(12)} / normal Inter Tight, sans-serif;

  --paragraph-10B: 600 #{p2r(18)} / normal Inter Tight, sans-serif;
  --paragraph-20B: 600 #{p2r(16)} / normal Inter Tight, sans-serif;
  --paragraph-30B: 600 #{p2r(14)} / #{p2r(18)} Inter Tight, sans-serif;
  --paragraph-40B: 600 #{p2r(12)} / normal Inter Tight, sans-serif;

  --shape-1: #{p2r(4)};
  --shape-2: #{p2r(8)};
  --shape-3: #{p2r(12)};
  --shape-4: #{p2r(16)};
  --shape-5: #{p2r(20)};

  --box-shadow-10: 0 1px 2px 0 rgba(18, 18, 23, 0.07);
  --box-shadow-20: 0 1px 3px 0 rgba(18, 18, 23, 0.1);
  --box-shadow-30: 0 1px 4px -1px rgba(18, 18, 23, 0.08);
  --box-shadow-35: 0 4px 6px -1px rgba(18, 18, 23, 0.08);
  --box-shadow-40: 0 10px 15px -3px rgba(18, 18, 23, 0.08);
  --box-shadow-50: 0 20px 25px -5px rgba(18, 18, 23, 0.1);
  --box-shadow-60: 0 25px 50px -12px rgba(18, 18, 23, 0.25);

  // --10: #fff;
  // --20: #fafafa;
  // --30: #efeff1;
  // --40: #e4e4e7;
  // --50: #a1a1aa;
  // --60: #71717a;
  // --70: #18181b;

  // --10R: #09090c;
  // --20R: #18181b;
  // --30R: #2e2e33;
  // --40R: #51515a;
  // --50R: #71717a;
  // --60R: #a1a1aa;
  // --70R: #f3f3f3;

  --base-level-10: #{light-dark(#fff, var(--grey-950))};
  --base-level-20: #{light-dark(var(--grey-50), var(--grey-900))};
  --base-level-30: #{light-dark(var(--grey-100), var(--grey-900))};
  --base-level-30R: #{light-dark(var(--grey-900), var(--grey-100))};
  --base-level-40: #{light-dark(var(--grey-200), var(--grey-700))};
  --base-level-50: #{light-dark(var(--grey-300), var(--grey-800))};
  --base-level-60: #{light-dark(var(--grey-500), var(--grey-400))};
  --base-level-60R: #{light-dark(var(--grey-400), var(--grey-500))};
  --base-level-70: #{light-dark(var(--grey-900), #fff)};
  --base-level-70R: #{light-dark(#fff, var(--grey-900))};

  --base-gradient-20: linear-gradient(180deg, var(--grey-400) 0%, var(--grey-500) 50%);
  --base-gradient-30: linear-gradient(180deg, var(--grey-300) 0%, var(--grey-500) 50%);
  --base-gradient-60: linear-gradient(180deg, var(--grey-700) 0%, var(--grey-900) 50%);
  --base-gradient-70: linear-gradient(180deg, var(--grey-600) 0%, var(--grey-900) 50%);

  // Palette light/dark variants
  --primary-level-20: #{light-dark(var(--primary-50), var(--primary-950))};
  --primary-level-30: #{light-dark(var(--primary-100), var(--primary-900))};
  --primary-level-40: #{light-dark(var(--primary-200), var(--primary-800))};
  --primary-level-50: #{light-dark(var(--primary-300), var(--primary-800))};
  --primary-level-50R: #{light-dark(var(--primary-800), var(--primary-300))};
  --primary-level-80: #{light-dark(var(--primary-900), var(--primary-300))};
  --primary-gradient-20: linear-gradient(180deg, var(--primary-400) 0%, var(--primary-500) 50%);
  --primary-gradient-30: linear-gradient(180deg, var(--primary-300) 0%, var(--primary-500) 50%);

  --accent-level-20: #{light-dark(var(--accent-50), var(--accent-950))};
  --accent-level-30: #{light-dark(var(--accent-100), var(--accent-900))};
  --accent-level-40: #{light-dark(var(--accent-200), var(--accent-800))};
  --accent-level-50: #{light-dark(var(--accent-300), var(--accent-800))};
  --accent-level-50R: #{light-dark(var(--accent-800), var(--accent-300))};
  --accent-level-80: #{light-dark(var(--accent-900), var(--accent-300))};
  --accent-gradient-20: linear-gradient(180deg, var(--accent-400) 0%, var(--accent-500) 50%);
  --accent-gradient-30: linear-gradient(180deg, var(--accent-300) 0%, var(--accent-500) 50%);

  --warn-level-20: #{light-dark(var(--warn-50), var(--warn-950))};
  --warn-level-30: #{light-dark(var(--warn-100), var(--warn-900))};
  --warn-level-40: #{light-dark(var(--warn-200), var(--warn-800))};
  --warn-level-50: #{light-dark(var(--warn-300), var(--warn-800))};
  --warn-level-50R: #{light-dark(var(--warn-800), var(--warn-300))};
  --warn-level-80: #{light-dark(var(--warn-900), var(--warn-300))};
  --warn-gradient-20: linear-gradient(180deg, var(--warn-400) 0%, var(--warn-500) 50%);
  --warn-gradient-30: linear-gradient(180deg, var(--warn-300) 0%, var(--warn-500) 50%);

  --error-level-20: #{light-dark(var(--error-50), var(--error-950))};
  --error-level-30: #{light-dark(var(--error-100), var(--error-900))};
  --error-level-40: #{light-dark(var(--error-200), var(--error-800))};
  --error-level-50: #{light-dark(var(--error-300), var(--error-800))};
  --error-level-50R: #{light-dark(var(--error-800), var(--error-300))};
  --error-level-80: #{light-dark(var(--error-900), var(--error-300))};
  --error-gradient-20: linear-gradient(180deg, var(--error-400) 0%, var(--error-500) 50%);
  --error-gradient-30: linear-gradient(180deg, var(--error-300) 0%, var(--error-500) 50%);

  --success-level-20: #{light-dark(var(--success-50), var(--success-950))};
  --success-level-30: #{light-dark(var(--success-100), var(--success-900))};
  --success-level-40: #{light-dark(var(--success-200), var(--success-800))};
  --success-level-50: #{light-dark(var(--success-300), var(--success-800))};
  --success-level-50R: #{light-dark(var(--success-800), var(--success-300))};
  --success-level-80: #{light-dark(var(--success-900), var(--success-300))};
  --success-gradient-20: linear-gradient(180deg, var(--success-400) 0%, var(--success-500) 50%);
  --success-gradient-30: linear-gradient(180deg, var(--success-300) 0%, var(--success-500) 50%);

  --dark-primary-text: var(--grey-900);
  --light-primary-text: #fff;

  &.dark {
    --sparkle-light: ;
    --sparkle-dark: initial;
    color-scheme: dark;
  }

  // TODO add a good way to toggle between light and dark mode served from the ui lib
  //
  // @media (prefers-color-scheme: dark) {
  //   --sparkle-light: ;
  //   --sparkle-dark: initial;
  //   color-scheme: dark;

  //   &.light {
  //     --sparkle-light: initial;
  //     --sparkle-dark: ;
  //     color-scheme: light;
  //   }
  // }
}
