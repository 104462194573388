@use '../helpers.scss' as *;

$spkIcon: true !default;

@if $spkIcon == true {
  spk-icon {
    font-size: p2r(20);
    line-height: 1em;
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    max-width: 1em;
    max-height: 1em;
    text-align: left;
    overflow: hidden;
    display: inline-block;

    &.large {
      font-size: p2r(32);
    }

    &.small {
      font-size: p2r(16);
    }

    &.inherit {
      font-size: inherit;
    }
  }
}
