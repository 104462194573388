@use '../helpers.scss' as *;

$spkCard: true !default;

@if $spkCard == true {
  spk-card {
    --card-bg: var(--base-level-10);
    --card-ibg: var(--base-level-20);
    --card-bc: var(--base-level-40);
    --card-sh: var(--box-shadow-10);
    --card-p: #{p2r(24)};
    --card-shp: #{p2r(8)};

    display: block;
    padding: var(--card-p);
    position: relative;
    z-index: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      background-color: var(--card-bg);
      border: 1px solid var(--card-bc);
      box-shadow: var(--card-sh);
      border-radius: var(--shape-3);
      inset: 0;
      z-index: -2;
    }

    &:after {
      background-color: var(--card-ibg);
      border-radius: var(--shape-2);
      inset: var(--card-shp);
      z-index: -1;
    }
  }
}
