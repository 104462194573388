@use '../helpers.scss' as *;

$spkSortable: true !default;

@if $spkSortable == true {
  [spkSortable] {
    position: relative;

    &.dragging {
      [draggable] {
        transition: transform 40ms linear;
      }
    }

    [draggable] {
      height: auto;
      transform: translate(0);
      transition: none;
      z-index: 2;
    }

    .sortable-placeholder {
      position: absolute;
      top: 0;
      z-index: 1;
    }

    [sort-handle] {
      cursor: move;
    }
  }
}
