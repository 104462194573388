@use '../helpers.scss' as *;
$spkAlert: true !default;

@if $spkAlert == true {
  spk-alert {
    --alert-ad: 400ms;
    --alert-bg: var(--base-level-10);

    --alert-bc: var(--base-level-40);
    --alert-state-ic: var(--base-level-70);
    --alert-close-ic: var(--base-level-60);
    --alert-title-c: var(--base-level-70);
    --alert-content-c: var(--base-level-60);
    --alert-bs: var(--box-shadow-10);

    max-height: #{p2r(300)};
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    opacity: 1;
    overflow: hidden;
    transition:
      max-height var(--alert-ad) ease-in-out,
      transform var(--alert-ad) ease-in-out,
      opacity var(--alert-ad) ease-in-out,
      margin-bottom var(--alert-ad) ease-in-out,
      padding-top var(--alert-ad) ease-in-out,
      padding-bottom var(--alert-ad) ease-in-out;
    transform: translateY(0);
    box-shadow: var(--alert-bs);

    .alert {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto auto;
      grid-template-areas:
        'stateIcon title closeIcon'
        '. content .';
      padding: p2r(8 12);
      align-items: flex-start;
      gap: p2r(8 12);
      border: 1px solid var(--alert-bc);
      border-radius: var(--shape-2);
      background-color: var(--alert-bg);
    }

    &.is-hidden {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
      margin-bottom: 0;
      overflow: hidden;
      transform: translateY(-40px);

      &::before {
        opacity: 0;
      }
    }

    &.animate-in {
      transition: none;
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateY(0px);

      &::before {
        opacity: 0;
      }
    }

    &.animate-out {
      max-height: 0;
      padding: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateY(-40px);

      &::before {
        opacity: 0;
      }
    }

    &.primary {
      --alert-state-ic: var(--primary-500);
      --alert-close-ic: var(--primary-500);
      --alert-title-c: var(--primary-level-80);
      --alert-content-c: var(--primary-level-80);
      --alert-bg: var(--primary-level-20);
      --alert-bc: var(--primary-level-40);
    }

    &.accent {
      --alert-state-ic: var(--accent-500);
      --alert-close-ic: var(--accent-500);
      --alert-title-c: var(--accent-level-80);
      --alert-content-c: var(--accent-level-80);
      --alert-bg: var(--accent-level-20);
      --alert-bc: var(--accent-level-40);
    }

    &.warn {
      --alert-state-ic: var(--warn-500);
      --alert-close-ic: var(--warn-500);
      --alert-title-c: var(--warn-level-80);
      --alert-content-c: var(--warn-level-80);
      --alert-bg: var(--warn-level-20);
      --alert-bc: var(--warn-level-40);
    }

    &.error {
      --alert-state-ic: var(--error-500);
      --alert-close-ic: var(--error-500);
      --alert-title-c: var(--error-level-80);
      --alert-content-c: var(--error-level-80);
      --alert-bg: var(--error-level-20);
      --alert-bc: var(--error-level-40);
    }

    &.success {
      --alert-state-ic: var(--success-500);
      --alert-close-ic: var(--success-500);
      --alert-title-c: var(--success-level-80);
      --alert-content-c: var(--success-level-80);
      --alert-bg: var(--success-level-20);
      --alert-bc: var(--success-level-40);
    }

    &:not(:has([content])) .alert {
      grid-template-rows: auto;
      grid-template-areas: 'stateIcon title closeIcon';
    }

    &:has([content]) .alert {
      background-color: var(--base-level-10);
      border: 1px solid var(--base-level-40);
      box-shadow: var(--box-shadow-50);

      .title {
        color: var(--base-level-70);
      }

      .close-icon {
        color: var(--grey-400);
      }

      .content {
        color: var(--base-level-60);
      }

      body.dark & {
        background-color: var(--grey-800);
        box-shadow: var(--box-shadow-50);
      }
    }

    .icon {
      grid-area: stateIcon;
      height: p2r(20);
      width: p2r(20);
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type:not(:empty) + .icon {
        display: none;
      }
    }

    [icon],
    .state-icon {
      color: var(--alert-state-ic);
    }

    .close-icon {
      grid-area: closeIcon;
      color: var(--alert-close-ic);
      transform: rotate(45deg);
      transform-origin: center;
    }

    .title {
      grid-area: title;
      color: var(--alert-title-c);
      padding-top: p2r(2);
    }

    .content {
      grid-area: content;
      color: var(--alert-content-c);

      &:empty {
        display: none;
      }
    }
  }
}
