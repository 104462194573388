@use '../helpers.scss' as *;
$spkAlert: true !default;

@if $spkAlert == true {
  spk-alert-container {
    width: 100%;
    position: relative;

    spk-alert {
      margin-bottom: p2r(8);

      &:first-of-type {
        margin-bottom: 0;
      }

      &.alert-is-hidden {
        margin-bottom: 0;
      }
    }

    .scroller {
      width: 100%;
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: p2r(8 16 16 8);
    }

    .container {
      flex-direction: column-reverse;
      display: flex;
    }

    .tray {
      display: flex;
      align-items: center;
      justify-content: center;
      width: p2r(24);
      height: p2r(24);
      background-color: var(--grey-500);
      color: var(--grey-contrast-500);
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      z-index: 1000;
    }
  }
}
