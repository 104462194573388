@use '../helpers.scss' as *;

$spkToggle: true !default;

@if $spkToggle == true {
  spk-toggle {
    --toggle-bg: var(--base-level-40);
    --toggle-b: 0;
    --togglek-bg: var(--base-level-30);
    --togglek-bs: var(--box-shadow-20);

    display: flex;
    align-items: center;
    gap: p2r(8);
    cursor: pointer;
    user-select: none;
    position: relative;

    label {
      display: flex;
      align-items: center;
      gap: p2r(8);
      position: relative;
      z-index: 1;
    }

    .box {
      display: block;
      width: p2r(34);
      height: p2r(20);
      cursor: pointer;
      position: relative;
      border-radius: 999px;
      background: var(--toggle-bg);
      border: var(--toggle-b);
      transition: background 150ms linear;
    }

    > input[type='checkbox'] {
      appearance: none;
      position: absolute;
      inset: 0;
      cursor: pointer;
      z-index: 0;
    }

    .knob {
      position: absolute;
      top: p2r(2);
      left: p2r(2);
      width: p2r(16);
      height: p2r(16);
      border-radius: 50%;
      background: var(--togglek-bg);
      box-shadow: var(--togglek-bs);
      box-sizing: border-box;
      transition: left 125ms ease-in-out;
    }

    &:has(input[type='checkbox']:checked),
    &.active {
      --togglek-bg: var(--base-level-60);

      .knob {
        left: p2r(16);
      }

      &.primary {
        --toggle-bg: var(--primary-level-30);
        --togglek-bg: var(--primary-500);
      }

      &.accent {
        --toggle-bg: var(--accent-level-30);
        --togglek-bg: var(--accent-500);
      }

      &.warn {
        --toggle-bg: var(--warn-level-30);
        --togglek-bg: var(--warn-500);
      }

      &.error {
        --toggle-bg: var(--error-level-30);
        --togglek-bg: var(--error-500);
      }

      &.success {
        --toggle-bg: var(--success-level-30);
        --togglek-bg: var(--success-500);
      }
    }

    &.outlined {
      --toggle-bg: var(--base-level-10);
      --togglek-bg: var(--base-level-40);
      --togglek-bs: none;
      --toggle-b: 1px solid var(--base-level-40);

      .knob {
        width: p2r(14);
        height: p2r(14);
      }
    }

    &.flat {
      --togglek-bg: #fff;

      &:has(input[type='checkbox']:checked),
      &.active {
        --togglek-bg: #fff;
        --toggle-bg: var(--base-level-60);

        &.primary {
          --toggle-bg: var(--primary-500);
        }

        &.accent {
          --toggle-bg: var(--accent-500);
        }

        &.warn {
          --toggle-bg: var(--warn-500);
        }

        &.error {
          --toggle-bg: var(--error-500);
        }

        &.success {
          --toggle-bg: var(--success-500);
        }
      }
    }

    &.raised {
      --togglek-bg: #fff;

      &:has(input[type='checkbox']:checked),
      &.active {
        --toggle-bg: var(--base-gradient-20);
        --togglek-bg: #fff;

        &.primary {
          --toggle-bg: var(--primary-gradient-20);
        }

        &.accent {
          --toggle-bg: var(--accent-gradient-20);
        }

        &.warn {
          --toggle-bg: var(--warn-gradient-20);
        }

        &.error {
          --toggle-bg: var(--error-gradient-20);
        }

        &.success {
          --toggle-bg: var(--success-gradient-20);
        }
      }
    }
  }
}
