@use '../helpers.scss' as *;

$spkDivider: true !default;

@if $spkDivider == true {
  spk-divider {
    --divider-c: var(--base-level-40);
    --divider-h: #{p2r(1)};

    display: flex;
    gap: p2r(8);
    align-items: center;
    text-align: center;

    &:empty {
      gap: 0;
    }

    &::before,
    &::after {
      content: '';
      background: var(--divider-c);
      height: var(--divider-h);
      flex: 1 0;
    }
  }
}
