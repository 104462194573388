@use '../helpers.scss' as *;

$spkSelect: true !default;

@if $spkSelect == true {
  spk-multi-select,
  spk-select {
    --miw: p2r(210);

    display: flex;

    spk-form-field {
      display: flex;
      min-width: var(--miw);
    }

    spk-popover {
      flex: 1 0;

      &:has(.sparkle-options:empty) .popover {
        opacity: 0;
      }
    }

    .overlay {
      position: fixed;
      inset: 0;
      opacity: 0;
      z-index: 1;
    }

    .deselect-indicator,
    .select-open-indicator {
      cursor: pointer;
      height: p2r(20);

      &:not(:has([open-indicator])) {
        transform: rotate(0deg);
        transition: transform 75ms linear;
        transform-origin: center;

        &.open {
          transform: rotate(540deg);
        }
      }

      > * {
        height: p2r(20);
        display: inline-block;
      }

      > * + .default-indicator {
        display: none;
      }
    }

    .search-indicator {
      > * {
        height: p2r(20);
        display: inline-block;
      }

      > * + .default-indicator {
        display: none;
      }
    }

    .display-value {
      display: flex;
      flex-wrap: wrap;
      gap: p2r(4);

      + input {
        opacity: 0;
        position: absolute;
        top: -200%;
        height: 0;
        width: 0;
      }

      spk-chip {
        cursor: pointer;
        user-select: none;

        spk-icon {
          color: inherit;
          font-size: p2r(16);
        }
      }
    }

    input {
      width: 100%;
    }

    .sparkle-options {
      padding: p2r(8 0);
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      max-height: p2r(180);
      overflow-y: auto;
      overflow-x: hidden;

      [spk-title] {
        font: var(--paragraph-30);
        color: var(--base-level-60);
        padding: p2r(8 12);
        // margin: p2r(0 8);
        width: calc(100% - #{p2r(16)});
        user-select: none;
      }

      spk-option,
      option {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: p2r(32);
        margin: p2r(0 8);
        padding: p2r(0 8);
        gap: p2r(8);
        width: calc(100% - #{p2r(16)});
        border-radius: var(--shape-2);
        appearance: none;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        color: var(--base-level-70);
        user-select: none;

        spk-checkbox {
          margin-left: auto;
        }

        &[disabled] {
          opacity: 0.3;
          cursor: initial;

          &:hover {
            background-color: transparent;
          }
        }

        spk-icon {
          font-size: p2r(16);
        }

        &.active,
        &:checked,
        &:hover {
          background-color: var(--base-level-30);
          color: var(--base-level-70);
        }

        &.focused {
          background-color: var(--primary-level-20);
          color: var(--base-level-70);

          &:hover {
            background-color: var(--primary-level-30);
          }
        }
      }
    }
  }
}
