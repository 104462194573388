@use '@sparkle-ui/core/styles/helpers' as *;
@use '@sparkle-ui/core/styles' as * with (
  $useInterTight: false,
  $useApexcharts: false
);

body {
  .spacer {
    flex: 1 0;
  }

  h2 {
    font: var(--title-30);
    margin: 0;
  }

  h3 {
    font: var(--paragraph-20);
    margin: 0;
  }
}

.sparkle-popup-menu {
  min-width: initial;

  &.right {
    button {
      justify-content: flex-end;
    }
  }
}

.markdown-output {
  h1 {
    margin: p2r(40 0 24);
  }

  h2 {
    margin: p2r(0 0 20);
  }

  h3 {
    margin: p2r(0 0 16);
  }

  h4,
  h5,
  h6,
  p {
    margin: p2r(0 0 12);
  }

  code {
    padding: p2r(4 8);
    border-radius: var(--shape-2);
    background-color: var(--base-level-60);
    color: var(--base-level-10);
    display: inline-block;
    font-size: 85%;
  }

  h1 code {
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--base-level-70);
    font-size: 100%;
  }
}

ol.line-numbers-ol {
  list-style: none;
  counter-reset: my-counter;

  li {
    counter-increment: my-counter;
    padding-left: p2r(4);

    &::marker {
      content: counter(my-counter) ' ';
    }
  }
}
